<template>
<div class="print-restaurantvoucher">
    <div class="print-button">
        <Button label="Print" icon="pi pi-print" class="p-button-sm p-button-secondary" @click="print()" />
    </div>
    <div style="padding: 5mm 10mm; text-align: justify">
        <div class="p-grid">
            <div class="p-col-3 p-mt-1" style="margin-left: 70px">
                <img alt="voucher-logo" style="width: 70%" src="../assets/images/logo-on-voucher.png" />
            </div>
            <div class="p-col-7">
                <div class="voucher-header" style="margin-left: 20px; text-align: left">
                    <span style="font-size: 11pt; font-weight: 600; color: #000">Angkor Destination Travel</span>
                    <p>P. O. Box.: 93148 | Siem Reap Angkor.</p>
                    <p>Office No. 629, Phum Mondul I, Svay Dangkum, Siem Reap Krong</p>
                    <p>Hotline: +855 (0)95 800 801 | +855 (0)12 695 295</p>
                    <p>Ph.: +855 (0)63 767 868 | Fax.: +855 (0)63 767 969</p>
                    <p>Email: info@angkordestination.com | www.angkordestination.com</p>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div style="width: 100%; text-align: center; margin-bottom: 15px; margin-top: 30px">
                <p style="font-weight: bold; font-size: 12pt">RESTAURANT VOUCHER</p>
            </div>
        </div>
        <div class="p-grid p-jc-end" style="margin: 0px 5px 0px 0px">
            <table style="font-weight: bold; width: 185px">
                <tr>
                    <td style="text-align: center; text-decoration: underline; background-color: #d1d1d1">Date:</td>
                    <td style="text-align: center; width: 100px;">{{ dayjs(new Date).format('DD-MM-YYYY') }}</td>
                </tr>
            </table>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <table class="editable-cell">
                    <thead>
                        <tr>
                            <td style="background-color: #d1d1d1; font-weight: bold">To:</td>
                            <td style="border-bottom: 1px solid #000">{{ restaurantBookingData.restaurant_name }}</td>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td style="font-weight: bold; text-align: right">Tour ref.:</td>
                            <td colspan="2" style="width: 160px; border-bottom: 2px solid #000; font-weight: bold">
                            {{ restaurantBookingData.tour_code }}
                            </td>
                        </tr>
                        <tr >
                            <td colspan="6" style="height: 10px;"></td>
                        </tr>
                        <tr>
                            <td style="background-color: #d1d1d1; font-weight: bold">Group Name:</td>
                            <td>{{ restaurantBookingData.tour_code }}</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td colspan="6">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="background-color: #d1d1d1; font-weight: bold">Reserved Date:</td>
                            <td>{{ restaurantBookingData.reserved_date }}</td>
                            <td>&nbsp;</td>
                            <td style="background-color: #d1d1d1; font-weight: bold; text-align: right">ETA:</td>
                            <td style="width: 90px; text-align: center" contenteditable="true">
                                {{ restaurantBookingData.meal == 'Breakfast' ? '6:30' : restaurantBookingData.meal == 'Lunch' ? '12:30' : restaurantBookingData.meal == 'Dinner' ? '18:30' : '' }}
                            </td>
                            <td>Private Bus</td>
                        </tr>
                        <tr>
                            <td colspan="6">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="background-color: #d1d1d1; font-weight: bold">Accommodation:</td>
                            <td colspan="2" contenteditable="true">{{ restaurantBookingData.hotel_name }}</td>
                            <td style="background-color: #d1d1d1; font-weight: bold; text-align: right">No. of Pax:</td>
                            <td style="text-align: center">{{ restaurantBookingData.pax }} {{ restaurantBookingData.child !== null ? ' + ' + restaurantBookingData.child + ' Child' : '' }}</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td colspan="6">&nbsp;</td>
                        </tr>
                    </tbody>
            </table>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-7">
                <table class="border-cell" style="width: 350px">
                    <tr>
                        <td colspan="2" style="background-color: #d1d1d1; text-align: center">Meals required:</td>
                    </tr>
                    <tr>
                        <td style="width: 146px">- Breakfast:</td>
                        <td>{{ restaurantBookingData.meal == 'Breakfast' ? restaurantBookingData.menu : '0' }}</td>
                    </tr>
                    <tr>
                        <td>- Lunch:</td>
                        <td>{{ restaurantBookingData.meal == 'Lunch' ? restaurantBookingData.menu : '0' }}</td>
                    </tr>
                    <tr>
                        <td>- Dinner:</td>
                        <td>{{ restaurantBookingData.meal == 'Dinner' ? restaurantBookingData.menu : '0' }}</td>
                    </tr>
                </table>
            </div>
            <div class="p-col-5">
                <table style="width: 200px; margin-left: 23%">
                    <tr>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align: left; border-bottom: 1px solid #000; font-weight: bold">Tour escorted by:</td>
                    </tr>
                    <tr>
                        <td style="width: 90px; font-weight: bold">Guide:</td>
                        <td>{{ restaurantBookingData.guide_name }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">Contact no.:</td>
                        <td>{{ restaurantBookingData.guide_phone }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">Driver:</td>
                        <td  style="white-space: nowrap; overflow: hidden; max-width:1px;">{{ restaurantBookingData.driver_name }}</td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold">Contact no.:</td>
                        <td>{{ restaurantBookingData.driver_phone }}</td>
                    </tr>
                </table>
            </div>
            <div class="p-col-12">
                <table class="editable-remark">
                    <tr>
                        <td style="text-align: right; background-color: #d1d1d1; text-decoration: underline; font-weight: bold">Remarks:</td>
                        <td style="border-bottom: 1px solid #000" contenteditable="true"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="width: 78%; border-bottom: 1px solid #000" contenteditable="true"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="border-bottom: 1px solid #000" contenteditable="true"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="border-bottom: 1px solid #000" contenteditable="true"></td>
                    </tr>
                </table>
            </div>
            <div style="width: 100%; margin-top: 15px; text-align: center; font-size: 8pt">
                <p>Please reserve as per this service voucher.</p>
                <p style="line-height:2.5">Thanks for your good service!</p>
            </div>
            <table style="width: 20%; margin-top: 20px">
                <tr>
                    <td>Authorized signature</td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td style="border-bottom: 1px dotted"></td>
                </tr>
            </table>
        </div>
    </div>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import BookingService from '../service/BookingService'

export default {
    setup() {
        const store = useStore()
        const route = useRoute()
        const bookingRestaurantID = route.query.bookingrestaurantid
        let restaurantBookingData = ref({})

        const bookingService = ref(new BookingService())

        store.dispatch('showSideBar', false) 
        store.dispatch('showTopBar', false)

        onMounted(() => {
            getRestaurantBooking()
        })

        const getRestaurantBooking = () => {
            if(bookingRestaurantID != 0) {
                bookingService.value.printRestaurantVoucher(bookingRestaurantID).then(data => {
                    if(!data.errorResponse) {                        
                        restaurantBookingData.value = data[0]
                    }
                })
            }
        }
        
        const print = () => {
            window.print()
        }

        return {
            dayjs,
            restaurantBookingData,
            print,
        }
    },
}
</script>

<style lang="scss" scoped>
.print-restaurantvoucher {
    top: 0;
	position: absolute;
    background-color: #fff;
	width: 720px;
	height: 100%;
	z-index: 9999;
    page-break-after: always;
}

p {
    color: #000;
    margin: 0;
    font-size: 8pt;
}
@page {
	size: A4;
	margin: 6mm 5mm 8mm 5mm;
}
@media print {
    table {
        width: 100%;
        vertical-align: bottom;
        color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
    }
    .editable-cell {
        tr:nth-child(5) td:nth-child(5), tr:nth-child(7) td:nth-child(2){
            border: 0 !important;
        }
    }
    .editable-remark {
        tr:nth-child(1) td:nth-child(2) {
            border: 0 !important;
            border-bottom: 1px solid #000 !important;
        }
    }
    .print-button {
        display: none;
    }
}
table {
  width: 100%;
  border-collapse: collapse;
  vertical-align: bottom;
  font-size: 8pt;
}

table td {
  height: 20px;
  vertical-align: bottom;
  color: #000;
  padding: 0px 3px;
}

.border-cell td {
  border: 1px solid black;
  font-weight: bold;
}

.table-border {
  border: 1px solid #000;
  font-weight: bold;
}

.voucher-header {
    margin-top: 2mm;
    p {
        line-height: 1.7;
        font-size: 8pt;
    }
}
.editable-cell {
    tr:nth-child(5) td:nth-child(5), tr:nth-child(7) td:nth-child(2){
        border: 1px solid blue;
    }
}
.editable-remark {
    tr:nth-child(1) td:nth-child(2) {
        border: 1px solid blue;
    }
}
.print-button {
    margin: 5px 5px;
}
</style>